.backgroundColor {
	background-color: rgba(255, 0, 0, 0.2);
}

.backgroundNav {
	padding: 2rem 0rem;
}

.input-group {
	width: 90%;
	margin: 0rem auto;
}
