.searchRow {
	margin: 3rem 0rem 1rem 0rem;
	margin-left: 8%;
	margin-right: 8%;
}

@media (max-width: 426px) {
	.searchRow {
		margin-left: 0;
		margin-right: 0;
	}
}
