body {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	animation-name: backgroundImg;
	animation-duration: 40s;
	animation-iteration-count: infinite;
}

@keyframes backgroundImg {
	0% {
		background: url(./img/valleyLandscape.jpg) no-repeat center center fixed,
			url(./img/blurRain.jpg), url(./img/foggyTrees.jpg),
			url(./img/greenFog.jpg);
		background-size: cover;
	}
	20% {
		background: url(./img/sunnyLandscape.jpg) no-repeat center center fixed;
		background-size: cover;
	}
	40% {
		background: url(./img/foggyTrees.jpg) no-repeat center center fixed;
		background-size: cover;
	}
	60% {
		background: url(./img/blurRain.jpg) no-repeat center center fixed;
		background-size: cover;
	}
	80% {
		background: url(./img/greenFog.jpg) no-repeat center center fixed;
		background-size: cover;
	}
	100% {
		background: url(./img/valleyLandscape.jpg) no-repeat center center fixed;
		background-size: cover;
	}
}

#root {
	margin-top: -2.5rem;
}
