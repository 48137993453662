.center {
	text-align: center;
}

.summaryText {
	font-size: 1.5rem;
}

.card {
	display: inline-block;
	width: 20rem;
}

@media (max-width: 321px) {
	.card {
		width: 16rem;
	}
}

.tempLayout {
	display: inline-flex;
}

.marginR {
	margin-right: 1.5rem;
}

.img {
	align-self: center;
}

.list-group-item {
	margin: 0;
}

.spacing {
	margin: 0rem auto;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}
